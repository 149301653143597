import React, {useState} from 'react'

import apiClient from '../../Request/http-common';
import { useMutation } from "react-query";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setAuthStatus } from '../Auth/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
   const user = useSelector((state) => state.user.user);   
  const [error, setErros] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

   const initialImage = {
      image: ''
  }
  const [productImage, setImage] = useState(initialImage);
  const  onFileChange = (event) => { 
      setImage({...productImage, image: event.target.files[0]});
      
    }; 



    const { isLoading: isLoggingOut, mutate: postLogout } = useMutation(
      async () => {
        return await apiClient.post(`/api/logout`);
      },
      {
        onSuccess: (res) => {
            if (res.data.message === 'successful') {
              dispatch(setAuthStatus('unauthenticated'));
              sessionStorage.removeItem('user');
              localStorage.removeItem('auth-token')              
              navigate('/login');
  
            }            
        },
        onError: (err) => {
          console.log(err.response?.data || err);
        },
      }
    );
  

   const { isLoading: isSendingRequest, mutate: postProduct } = useMutation(
      async () => {
          const formData = new FormData();
          formData.append('image', productImage.image);
  
        return await apiClient.post(`/api/profile`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      },
      {
        onSuccess: (res) => {
          if (res.data.status === 'success') {
            setSuccessMessage(res.data.message);
          }
        },
        onError: (err) => {   
          if (err.response?.data.hasOwnProperty('errors')) {
            let myerror = err.response?.data.errors || err;         
            setErros(myerror)   
                     
        }else{
            let errorMessage = err.response?.data.message || err ;
            setGeneralError(errorMessage);
  
            
        }       
               
        },
      }
    );
  return (
    <div>
      <div class="appointment-details" style={{marginTop: '50px'}}>
                           <div class="details-top-section profile-main-page">
                              <div class="clinic-main">
                                 <div class="charges-details medicine-payment-details" >
                                    <div class="profile-main-img" style={{marginTop: '20px'}}>
                                       <img style={{height: '150px', width: '150px', borderRadius: '75px'}} src={productImage.image !== '' ? URL.createObjectURL(productImage.image) : user.profile} alt="" />

                                       <label htmlFor="input"><img class="camera-icon" src="images/camera.png" alt="" /></label>
                                       <input onChange={(e)=>{onFileChange(e); 

                                          setTimeout(() => {
                                             postProduct()
                                          }, 1000);
                                       }} type="file" id="input" style={{display: 'none'}} />
                                    </div>
                                    
                                    <div class="profile-top-title" style={{marginTop: '30px'}}>
                                    <p style={{color: 'red'}}>{isSendingRequest && 'updating profile please wait...'}</p>
                                       <h2>{user.name}</h2>
                                       <p>{user.email}</p>
                                       <p>PATIENT ID: {user.patient_id}</p>

                                       <button onClick={postLogout} className="btn btn-danger text-white">
                                          
                                       {isLoggingOut? 'logging out...' : 'Logout'}   
                                       </button>
                                    </div>
                                    
                                 </div>
                              </div>
                           </div>
                           <div class="specialties-main profile-content-box">
                              <div class="special-main specialties-box">
                                
                                 <Link to="/pills-reminder" class="special-box">
                                    <div class="special-text-box">
                                       <div class="appointments"><img src="images/pills.png" alt="" /></div>
                                       <span><a href="#">Pills Reminder</a></span>
                                    </div>
                                 </Link>
                                 <Link to="/doctors" class="special-box">
                                    <div class="special-text-box">
                                       <div class="appointments"><img src="images/doctor-icon1.png" alt="" /></div>
                                       <span><a href="#">My Doctors</a></span>
                                    </div>
                                 </Link>
                                 <Link to="/pharmacy" class="special-box">
                                    <div class="special-text-box">
                                       <div class="appointments"><img src="images/medicine-icn.png" alt="" /></div>
                                       <span><a href="#">My Medicine</a></span>
                                    </div>
                                 </Link>
                                 <Link to="/list-test" class="special-box">
                                    <div class="special-text-box">
                                       <div class="appointments"><img src="images/appointments.png" alt="" /></div>
                                       <span><a href="#">My Diagnostics</a></span>
                                    </div>
                                 </Link>
                                 <Link href="/payment" class="special-box">
                                    <div class="special-text-box">
                                       <div class="appointments"><img src="images/payment-icn.png" alt="" /></div>
                                       <span><a href="#">Payment</a></span>
                                    </div>
                                 </Link>
                                
                              </div>
                           </div>
                        </div>
    </div>
  )
}

export default Profile
