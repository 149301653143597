import { createSlice } from "@reduxjs/toolkit";

const initialState = {

    adminambulance: JSON.parse(localStorage.getItem('adminambulance')) || [],
    adminappointment: JSON.parse(localStorage.getItem('adminappointment')) || [],
    admindiagnostic: JSON.parse(localStorage.getItem('admindiagnostic')) || [],
    adminpharmacy: JSON.parse(localStorage.getItem('adminpharmacy')) || [],
    adminpill_reminder: JSON.parse(localStorage.getItem('adminpill_reminder')) || [],
    admindoctors: JSON.parse(localStorage.getItem('admindoctors')) || [],
    adminpayment_methods: JSON.parse(localStorage.getItem('adminpayment_methods')) || [],
    adminpayments: JSON.parse(localStorage.getItem('adminpayments')) || [],
    adminusers: JSON.parse(localStorage.getItem('adminusers')) || [],

}

export const adminhistorySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        setadminAmbulance: (state, action) => {
            state.adminambulance = action.payload;  
            localStorage.setItem('adminambulance', JSON.stringify(state.adminambulance));
        },

        setadminAppointment: (state, action) => {
            state.adminappointment = action.payload;  
            localStorage.setItem('adminappointment', JSON.stringify(state.adminappointment));
        },

        setadminDiagnostic: (state, action) => {
            state.admindiagnostic = action.payload;  
            localStorage.setItem('admindiagnostic', JSON.stringify(state.admindiagnostic));
        },

        setadminPharmacy: (state, action) => {
            state.adminpharmacy = action.payload;  
            localStorage.setItem('adminpharmacy', JSON.stringify(state.adminpharmacy));
        },

        setadminPillReminder: (state, action) => {
            state.adminpill_reminder = action.payload;  
            localStorage.setItem('adminpill_reminder', JSON.stringify(state.adminpill_reminder));
        },

        setadminDoctors: (state, action) => {
            state.admindoctors = action.payload;  
            localStorage.setItem('admindoctors', JSON.stringify(state.admindoctors));
        },

        setadminPaymentMethods: (state, action) => {
            state.adminpayment_methods = action.payload;  
            localStorage.setItem('adminpayment_methods', JSON.stringify(state.adminpayment_methods));
        },

        setadminPayments: (state, action) => {
            state.adminpayments = action.payload;  
            localStorage.setItem('adminpayments', JSON.stringify(state.adminpayments));
        },

        setadminUsers: (state, action) => {
            state.adminusers = action.payload;  
            localStorage.setItem('adminusers', JSON.stringify(state.adminusers));
        },

        

    }
})

export const {setadminAmbulance, setadminUsers, setadminPayments, setadminAppointment, setadminDiagnostic, setadminPharmacy, setadminPillReminder, setadminDoctors, setadminPaymentMethods } = adminhistorySlice.actions;
export default adminhistorySlice.reducer;


