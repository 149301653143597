import { configureStore } from "@reduxjs/toolkit";
import historyReducer from "../Request/History/historySlice";
import authReducer from "../components/Auth/authSlice";
import userReducer from "../components/Auth/userSlice";
import paymentReducer from "../components/Payment/paymentSlice";
import adminhistoryReducer from "../Request/History/adminhistorySlice";
export const store = configureStore({
    reducer: {
        history: historyReducer,
        auth: authReducer,
        user: userReducer,
        cart: paymentReducer,
        admin: adminhistoryReducer
    }
})