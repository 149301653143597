import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ListAdminPayments = () => {

  const payments = useSelector((state) => state.admin.adminpayments);  
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

  return (
    <div style={{marginTop: '70px', padding: '10px'}} className="">
      <div className='card' style={{boxShadow: '2px 5px 4px 0px gray', backgroundColor: 'white', color: 'black'}}>
        {payments.length > 0 &&
        <div className='table-responsive'>
         <table className="table special-box responsive">

         <thead>
           <tr>
             <th scope="col">Amount</th>
             <th scope="col">User ID</th>
             <th scope="col">status</th>
             
             <th scope="col">method</th>
             <th scope="col">address/details</th>
             <th scope="col">description</th>
             <th scope="col">edit</th>
           </tr>
         </thead>

         <tbody>
           
            {payments.map(payment => (
              <tr key={payment.id}>
              <td>{payment.amount}</td>
                <td>{payment.user.patient_id}</td>
                <td>{payment.status}</td>
                <td>{payment.method}</td>
                <td>{payment.address}</td>
                <td>{payment.description}</td>
                <td><Link to={`/view-payment/${payment.id}`} className='btn btn-secondary pill'>Edit</Link></td>
              </tr>
            ))}             

         </tbody>

       </table>
       </div>
        }
       
        </div>
    </div>
  )
}

export default ListAdminPayments
