import React from 'react'
import { useSelector } from 'react-redux';
import Footer from '../../layouts/Footer';
const ListReminder = () => {

  const reminders = useSelector((state) => state.history.pill_reminder);
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

  return (
    <div>
      <div className="appointment-page">
          
          <div className="morning-main-box">
            <div className="morning-slot">
              <h2>Today's Activity</h2>

              {reminders.length > 0 &&
              <div className="pills-reminder-main-box">

                {reminders.map(reminder=>(
                    <div className="pills-reminder-box">
                      <div className="pills-reminder-left"><img src="images/reminder-img.png" alt /></div>
                      <div className="pills-reminder-right">
                        <h2>{reminder.name}</h2>
                        <div className="reminder-text">{reminder.time}</div>
                        <div className="edit-icon"><img src="images/edit.png" alt /></div>
                      </div>
                    </div>
                ))}
              
              </div>
              }

            </div>
          </div>
        </div>
    </div>
  )
}

export default ListReminder
