import React, {useState} from 'react'
import apiClient from '../../Request/http-common';
import { useMutation } from "react-query";
import { setAuthStatus, setToken } from './authSlice';
import { setUser } from './userSlice';
import { useDispatch } from 'react-redux';
const Login = () => {

  const dispatch = useDispatch();
  const initialLogin = {
    email: '',
    password: '',

}

  const [error, setErros] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [productForm, setproductForm] = useState(initialLogin);

  const onChange = (e) =>
    setproductForm({ ...productForm, [e.target.name]: e.target.value });

  const { isLoading: isSendingRequest, mutate: postProduct } = useMutation(
    async () => {

      return await apiClient.post(`/api/login`, {
        email: productForm.email,
        password: productForm.password,
        
      });
    },
    {
      onSuccess: (res) => {
            localStorage.setItem('auth-token', res.data.access_token);
            dispatch(setUser(res.data.user));
            dispatch(setAuthStatus('authenticated'));
            dispatch(setToken(res.data.access_token));
            setTimeout(() => {
              window.location.href  = '/';
            }, 1000);
      },
      onError: (err) => {  

        if (err.response?.data.hasOwnProperty('errors')) {
          let myerror = err.response?.data.errors || err;         
          setErros(myerror)   
                   
      }else{
          let errorMessage = err.response?.data.message || err ;
          setGeneralError(errorMessage);

          
      }      
             
      },
    }
  );


  const initialImage = {
    image: ''
}
const [productImage, setImage] = useState(initialImage);
const  onFileChange = (event) => { 
    setImage({...productImage, image: event.target.files[0]});
  };
  
  const onSubmit = async() =>{
    

    await apiClient.get("/sanctum/csrf-cookie").then(() => {
      postProduct();
        })
    
  }

  return (
    <div className="main-container" style={{backgroundColor: '#fff'}}>
  <div id="app">
    <div className="page">
      <div className="page-content">
        <div className="sign-in-page segments">
          {/* <div className="navbar sign-header">
            <div className="navbar-inner">
              <div className="back-link">
                <a className="link back" href="index.html">
                  <img className="back-icon" src="images/back-icon.svg" alt />
                </a>
              </div>
            </div>
          </div> */}
          <div className="sign-up-section">
            <div className="sign-up-top">
              <h2>Account Login</h2>
              <p>fill the form to login to account</p>
            </div>
            {console.log(error)}
            <div className="sign-up-form">
              
              <div className="sign-form-fild">
                <label>email</label>
                <input onChange={onChange} name='email' type="email" placeholder="me@example.com" /> 
                <div style={{color: 'red'}}>
                {(error !== '' && error.email)}
                </div>  
              </div>

              <div className="sign-form-fild">
                <label>password</label>
                <input onChange={onChange} name='password' type="password" placeholder='********' />  
                <div style={{color: 'red'}}>
                {(error !== '' && error.password)}
                </div>
              </div>

            

            <div style={{color: 'red', marginTop: '20px', textAlign: 'center'}}>
                {(generalError !== '' && generalError.message || generalError) }
              </div>


              <div className="sign-botton">
                <a onClick={onSubmit} className="sign-up-btn" style={{cursor: 'pointer', color: 'white'}}>
                  
                  {isSendingRequest ? 'processing...': 'Login'}
                  
                </a>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Login
