import React from 'react'
import { useSelector } from 'react-redux'
import PaymentButton from '../Payment/PaymentButton';
import Footer from '../../layouts/Footer';

const ListPharmacy = () => {
    const pharmacy = useSelector((state) => state.history.pharmacy);
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

  return (
    <div>
     <div className="appointment-details" style={{marginTop: '20px'}} >
                
                {pharmacy.length > 0 &&

                 <div className="special-main specialties-box categories-top-section" style={{padding : '10px'}}>
                    
                    <p></p>
                    {pharmacy.map(drug => (
                        <div className="special-box" >
                            <div className="special-text-box">
                            
                            <img src={drug.image} alt="user" style={{ width: '100%', borderRadius: '5px', marginTop: '5px'}} />
                            <span><a href="">{drug.name}</a></span>

                            <div style={{fontSize: '11px', marginTop: '8px', color: '#000000'}}>Cost: {formatPrice(drug.amount)}</div>

                            <div className='' style={{display: 'flex', justifyContent: 'center',  alignItems: 'center', marginTop: '8px'}}>
                            <div className='' style={{height: '8px', width: '8px', borderRadius: '4px', backgroundColor: 'red'}}></div>
                            
                            <div className="" style={{marginLeft: '5px', fontSize: '14px', marginTop: '-5px', color: 'red'}}>{drug.status}</div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center'}}>                        
                            <PaymentButton  product={drug} name="Pay Now" />
                            </div>
                            </div>                        
                        </div>
                    ))}
                 
             </div>
                }
               
             
            </div>

    </div>
  )
}

export default ListPharmacy
