import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ListAdminPillReminder = () => {

  const pills = useSelector((state) => state.admin.adminpill_reminder);  
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

  return (
    <div style={{marginTop: '70px', padding: '10px'}} className="">
      <div className='card' style={{boxShadow: '2px 5px 4px 0px gray', backgroundColor: 'white', color: 'black'}}>
        {pills.length > 0 &&
        <div className='table-responsive'>
         <table className="table special-box responsive">

         <thead>
           <tr>
             <th scope="col">Name</th>
             <th scope="col">User ID</th>
             <th scope="col">time</th>
             <th scope="col">date</th>
             <th scope="col">edit</th>
             <th scope="col">image</th>
           </tr>
         </thead>

         <tbody style={{}}>
           
            {pills.map(pill => (
              <tr key={pill.id}>
              <td>{pill.name}</td>
                <td>{pill.user_id}</td>
                <td>{pill.time}</td>
                <td>{pill.date}</td>
                <td><Link to={`/view-pill/${pill.id}`} className='btn btn-secondary pill'>Edit</Link></td>               
                <td><img style={{height: '30px', width: '30px'}} src={pill.image}/></td>
                
              </tr>
            ))}             

         </tbody>

       </table>
       </div>
        }
       
        </div>
    </div>
  )
}

export default ListAdminPillReminder
