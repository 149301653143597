import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
const ListAdminAmbulance = () => {
  
  const ambulances = useSelector((state) => state.admin.adminambulance);  
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

  return (
    <div style={{marginTop: '70px', padding: '10px'}} className="">
      <div className='card' style={{boxShadow: '2px 5px 4px 0px gray', backgroundColor: 'white', color: 'black'}}>
        {ambulances.length > 0 &&
        <div className='table-responsive'>
         <table className="table special-box responsive">

         <thead>
           <tr>
             <th scope="col">LOCATION</th>
             <th scope="col">USER ID</th>
             <th scope="col">AMOUNT</th>
             
             <th scope="col">EDIT</th>
             <th scope="col">STATUS</th>
           </tr>
         </thead>

         <tbody style={{}}>
           
            {ambulances.map(ambulance => (
              <tr key={ambulance.id}>
              <td>{ambulance.location}</td>
                <td>{ambulance.user_id}</td>
                <td>{ambulance.amount}</td>
                <td><Link to={`/view-ambulance/${ambulance.id}`} className='btn btn-secondary pill'>Edit</Link></td>
                <td>{ambulance.status}</td>
                
              </tr>
            ))}             

         </tbody>

       </table>
       </div>
        }
       
        </div>
    </div>
  )
}

export default ListAdminAmbulance
