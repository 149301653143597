import React from 'react'
import { useSelector } from 'react-redux'
import PaymentButton from '../Payment/PaymentButton';
import Footer from '../../layouts/Footer';
const AllDoctors = () => {
    const doctors = useSelector((state) => state.history.doctors);
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

  return (
    <div>
        <div className="specialties-main">
                        
                        <div className="tabs-bottom-section">
                        <div id="All" className="tabs-container city">
                            <div className="tabs-product-section">
                            {doctors.map(doctor => (
                                 <div key={doctor.id} className="tabs-product-main">
                                 <div className="tabs-product-box">
                                 <div className="tabs-product-left"><a href=""><img src={doctor.profile} style={{width: '100%', borderRadius: '5px'}} /></a></div>
                                 <div className="tabs-product-right">
                                     <h2><a href="">{doctor.name}</a></h2>
                                     <p>{doctor.category} - {doctor.description}</p>
                                     <div className="time">{doctor.time}</div>
                                     <div className="price-exp-box">
                                     <span>{formatPrice(doctor.amount)}</span>
                                     <span>Exp.: {doctor.experience}.</span>
                                     {/* <span className="heart">251</span> */}
                                     </div>
                                 </div>
                                 </div>
                                 <div className="appointment-box">
                                 
                                 <div className="appointment-right">
                                     {/* <span className="call"><a href="video-call.html">Call</a></span> */}
                                     <PaymentButton name="Appointment" product={doctor}/>
                                     
                                 </div>
                                 </div>
                             </div>
                            ))}
                            </div>
                        </div>
                        </div>
                    </div>

    </div>
  )
}

export default AllDoctors
