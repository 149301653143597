import React, {useState, useEffect} from 'react'
import apiClient from '../../Request/http-common';
import { useMutation } from "react-query";

import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const EditDiagnostic = () => {

  const initialLogin = {
    title: '',
    user_id: '',
    price: '',
    description: '',
    status: '',

}

  const [error, setErros] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [productForm, setproductForm] = useState(initialLogin);
  const [successMessage, setSuccessMessage] = useState(null);


  const initialImageReport = {
    image: ''
}
const [productImageReport, setImageReport] = useState(initialImageReport);
const  onFileChangeReport = (event) => { 
  setImageReport({...productImageReport, image: event.target.files[0]});
  }; 


  
  const params = useParams();
  const [diagnostic, setdiagnostic] = useState(null);
  const { isLoading: isLoadingdiagnostics, refetch: getdiagnostics } = useQuery(
      "edit-diagnostic",
      async () => {
        return await apiClient.get(`/api/edit-diagnostic/${params.id}`);
      },
      {
        enabled: false,
        onSuccess: (res) => {
          if (res.data.status === 'success') {
              setdiagnostic(res.data.diagnostic);
              setproductForm({...productForm,

                  title: res.data.diagnostic.title,
                  user_id: res.data.diagnostic.user_id,
                  price: res.data.diagnostic.amount,
                  description: res.data.diagnostic.description,
                  status: res.data.diagnostic.status,
              });
          }
        },
        onError: (err) => {
          // console.log(err.response?.data || err);
        },
      }
    );

  const onChange = (e) =>
    setproductForm({ ...productForm, [e.target.name]: e.target.value });

  const { isLoading: isSendingRequest, mutate: postProduct } = useMutation(
    async () => {
        const formData = new FormData();
        formData.append('diagnostic_id', params.id);
        formData.append('title', productForm.title);
        formData.append('amount', productForm.price);
        formData.append('user_id', productForm.user_id);
        formData.append('description', productForm.description);
        formData.append('status', productForm.status);
        formData.append('profile', productImage.image);
        formData.append('report', productImageReport.image);


      return await apiClient.post(`/api/update-diagnostics`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      onSuccess: (res) => {
        if (res.data.status === 'success') {
          setSuccessMessage(res.data.message);
        }
      },
      onError: (err) => {   
        if (err.response?.data.hasOwnProperty('errors')) {
          let myerror = err.response?.data.errors || err;         
          setErros(myerror)   
                   
      }else{
          let errorMessage = err.response?.data.message || err ;
          setGeneralError(errorMessage);

          
      }       
             
      },
    }
  );


  const initialImage = {
    image: ''
}
const [productImage, setImage] = useState(initialImage);
const  onFileChange = (event) => { 
    setImage({...productImage, image: event.target.files[0]});
  }; 

  useEffect(() => {
    getdiagnostics();
  }, []);

  return (
    <div className="main-container" style={{backgroundColor: '#fff'}}>
        {diagnostic !== null &&
  <div id="app">
    <div className="page">
      <div className="page-content">
        <div className="sign-in-page segments">
          {/* <div className="navbar sign-header">
            <div className="navbar-inner">
              <div className="back-link">
                <a className="link back" href="index.html">
                  <img className="back-icon" src="images/back-icon.svg" alt />
                </a>
              </div>
            </div>

          </div> */}
          <div className="sign-up-section">
            <div className="sign-up-top">
              <h2>Diagnostic Edit</h2>
              <p>fill the form to edit diagnostics</p>
            </div>
            <div className="sign-up-form">
              <div className="sign-form-fild">
                <label>Title</label>
                <input defaultValue={diagnostic.title} onChange={onChange} name='title' type="text" placeholder="Title" />   
                <div style={{color: 'red'}}>
                {(error !== '' && error.title)}
                </div>
              </div>
              {/* <div className="sign-form-fild">
                <label>category</label>
                <input onChange={onChange} name='category' type="text" placeholder="cardiology" /> 
                <div style={{color: 'red'}}>
                {(error !== '' && error.category)}
                </div>  
              </div> */}
              <div className="sign-form-fild">
                <label>Price</label>
                <input defaultValue={diagnostic.amount} onChange={onChange} name='price' type="number" placeholder='2000' />  
                <div style={{color: 'red'}}>
                {(error !== '' && error.price)}
                </div>
              </div>

              <div className="sign-form-fild">
                <label>Patient ID </label>
                <input defaultValue={diagnostic.user_id} onChange={onChange} name='user_id' type="text" placeholder='982392389' /> 
                <div style={{color: 'red'}}>
                {(error !== '' && error.user_id)}
                </div> 
              </div>

              {/* <div className="sign-form-fild">
                <label>experience</label>
                <input onChange={onChange} name='experience' type="text" placeholder='2000' />  
                <div style={{color: 'red'}}>
                {(error !== '' && error.experience)}
                </div>
              </div> */}

              <div className="sign-form-fild">
                <label>status</label>
                <input defaultValue={diagnostic.status} onChange={onChange} name='status' type="text" placeholder='active' /> 
                <div style={{color: 'red'}}>
                {(error !== '' && error.status)}
                </div> 
              </div>

              <div className="sign-form-fild">
                <label>description</label>
                <textarea defaultValue={diagnostic.description} style={{width: '100%', height: '120px'}} onChange={onChange} name='description' type="text" placeholder='write...' >
                
                </textarea>
                <div style={{color: 'red'}}>
                {(error !== '' && error.description)}
                </div> 
              </div>

             <div className="clinic-main">
             
              <div className="charges-details text-center">
              {productImage.image !== '' ?
                            <img style={{width: "100px", height: "100px", borderRadius: '50px'}} src={URL.createObjectURL(productImage.image)} alt="product"/>
                        :
                        
                        <img style={{width: "100px", height: "100px", borderRadius: '50px'}} src={diagnostic.image} alt="product"/>

                        }
                <label htmlFor="input" className="prescription-btn">Image</label>
                
                
                <input onChange={onFileChange} type="file" id="input" style={{display: 'block'}} />
              </div>
              <div style={{color: 'red'}}>
                {(error !== '' && error.product_image)}
                </div>
            </div>

            <div className="clinic-main">
             
            <label htmlFor="report" className="prescription-btn">REPORT DOCUMENT</label>
                <input onChange={onFileChangeReport} type="file" id='report' style={{display: 'block'}} />
              <div style={{color: 'red'}}>
                {(error !== '' && error.report)}
              </div>
            </div>

            

            <div style={{color: 'red', marginTop: '20px', textAlign: 'center'}}>
                {(generalError !== '' && generalError.message || generalError) }
              </div>
                    
                  {successMessage !== null &&
                    <div style={{color: 'green', marginTop: '20px', textAlign: 'center'}}>
                    {successMessage}
                  </div>
                  }
              


              <div className="sign-botton">
                <a onClick={postProduct} className="sign-up-btn" style={{cursor: 'pointer', color: 'white'}}>
                  {isSendingRequest ? 'Updating...': 'Update Product'}
                </a>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
</div>

  )
}

export default EditDiagnostic
