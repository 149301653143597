import React, {useEffect} from 'react'
import apiClient from '../http-common';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { setadminAmbulance, setadminUsers, setadminAppointment, setadminPayments, setadminDiagnostic, setadminPharmacy, setadminPillReminder, setadminDoctors, setadminPaymentMethods } from './adminhistorySlice';
export const AdminHistory = () => {

  const dispatch = useDispatch();

    const { isLoading: isLoadingHistory, refetch: getHistory } = useQuery(
        "admin-history",
        async () => {
          return await apiClient.get("/api/admin-history");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
              dispatch(setadminAmbulance(res.data.ambulances))
              dispatch(setadminAppointment(res.data.appointments))
              dispatch(setadminDiagnostic(res.data.diagnostics))
              dispatch(setadminPharmacy(res.data.pharmacy))
              dispatch(setadminPillReminder(res.data.pill_reminder))
              dispatch(setadminDoctors(res.data.doctors))
              dispatch(setadminPaymentMethods(res.data.payment_methods))
              dispatch(setadminPayments(res.data.payments))
              dispatch(setadminUsers(res.data.users))
              
              

            }
          },
          onError: (err) => {
            console.log(err.response?.data || err);
          },
        }
      );

      useEffect(() => {
        getHistory();
      }, []);
 
}

export default AdminHistory
