import React, {useState, useEffect} from 'react'
import apiClient from '../../Request/http-common';
import { useMutation } from "react-query";


import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const EditPayment = () => {

  const initialLogin = {
    name: '',
    method: '',
    amount: '',
    address: '',
    status: '',
    description: '',
    user_id: ''

}

  const [error, setErros] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [productForm, setproductForm] = useState(initialLogin);
  const [successMessage, setSuccessMessage] = useState(null);


  const params = useParams();
  const [payment, setpayment] = useState(null);
  const [users, setUsers] = useState(null);
  const { isLoading: isLoadingpayments, refetch: getpayments } = useQuery(
      "edit-payment",
      async () => {
        return await apiClient.get(`/api/edit-payment/${params.id}`);
      },
      {
        enabled: false,
        onSuccess: (res) => {
          if (res.data.status === 'success') {
              setpayment(res.data.payment);
              setUsers(res.data.users);
              setproductForm({...productForm,

                    name: res.data.payment.name,
                    method: res.data.payment.method,
                    amount: res.data.payment.amount,
                    address: res.data.payment.address,
                    status: res.data.payment.status,
                    description: res.data.payment.description,
                    user_id: res.data.payment.user_id
              });
          }
        },
        onError: (err) => {
          // console.log(err.response?.data || err);
        },
      }
    );


  const onChange = (e) =>
    setproductForm({ ...productForm, [e.target.name]: e.target.value });

  const { isLoading: isSendingRequest, mutate: postProduct } = useMutation(
    async () => {
        const formData = new FormData();
        formData.append('payment_id', params.id);
        formData.append('name', productForm.name);
        formData.append('method', productForm.method);
        formData.append('amount', productForm.amount);
        formData.append('address', productForm.address);
        formData.append('description', productForm.description);
        formData.append('status', productForm.status);
        formData.append('user_id', productForm.user_id);
        formData.append('profile', productImage.image);
        

      return await apiClient.post(`/api/update-payment`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      onSuccess: (res) => {
        if (res.data.status === 'success') {
          setSuccessMessage(res.data.message);
        }
      },
      onError: (err) => {   
        if (err.response?.data.hasOwnProperty('errors')) {
          let myerror = err.response?.data.errors || err;         
          setErros(myerror)   
                   
      }else{
          let errorMessage = err.response?.data.message || err ;
          setGeneralError(errorMessage);          
      }       
             
      },
    }
  );


  const initialImage = {
    image: ''
}
const [productImage, setImage] = useState(initialImage);
const  onFileChange = (event) => { 
    setImage({...productImage, image: event.target.files[0]});
  }; 

  useEffect(() => {
    getpayments();
  }, []);

  return (
    <div className="main-container" style={{backgroundColor: '#fff'}}>
  <div id="app">
    {payment !== null && 
    <div className="page">
      <div className="page-content">
        <div className="sign-in-page segments">
          {/* <div className="navbar sign-header">
            <div className="navbar-inner">
              <div className="back-link">
                <a className="link back" href="index.html">
                  <img className="back-icon" src="images/back-icon.svg" alt />
                </a>
              </div>
            </div>
          </div> */}
          <div className="sign-up-section">
            <div className="sign-up-top">
              <h2>Update payment</h2>
              <p>fill the form to edit payment</p>
            </div>
            <div className="sign-up-form">
              <div className="sign-form-fild">
                <label>Patient Name</label>
               
                <select onChange={onChange} name='user_id' style={{width: '100%', height: '40px'}}
                  
                  >
                    <option>
                      Select User
                    </option>
                    {users !== null &&
                    users.map(user => (
                      <option key={user.id} value={user.id}
                      >
                        {user.name}
                      </option>
                    ))
                    
                    }
                  </select>
              </div>

              <div className="sign-form-fild">
                <label>method</label>
                <input defaultValue={payment.method} onChange={onChange} name='method' type="text" placeholder="cardiology" /> 
                <div style={{color: 'red'}}>
                {(error !== '' && error.method)}
                </div>  
              </div>
              <div className="sign-form-fild">
                <label>amount</label>
                <input defaultValue={payment.amount} onChange={onChange} name='amount' type="number" placeholder='2000' />  
                <div style={{color: 'red'}}>
                {(error !== '' && error.amount)}
                </div>
              </div>

              <div className="sign-form-fild">
                <label>address</label>
                <input defaultValue={payment.address} onChange={onChange} name='address' type="text" placeholder='2000' /> 
                <div style={{color: 'red'}}>
                {(error !== '' && error.address)}
                </div> 
              </div>

              {/* <div className="sign-form-fild">
                <label>experience</label>
                <input defaultValue={payment.experience} onChange={onChange} name='experience' type="text" placeholder='2000' />  
                <div style={{color: 'red'}}>
                {(error !== '' && error.experience)}
                </div>
              </div> */}

              <div className="sign-form-fild">
                <label>status</label>
                <input defaultValue={payment.status} onChange={onChange} name='status' type="text" placeholder='2000' /> 
                <div style={{color: 'red'}}>
                {(error !== '' && error.status)}
                </div> 
              </div>

              <div className="sign-form-fild">
                <label>description</label>
                <textarea defaultValue={payment.description} style={{width: '100%', height: '120px'}} onChange={onChange} name='description' type="text" placeholder='write...' >
                </textarea>
                <div style={{color: 'red'}}>
                {(error !== '' && error.description)}
                </div> 
              </div>

             {/* <div className="clinic-main">
             
              <div className="charges-details text-center">
              {productImage.image !== '' ?
                            <img style={{width: "100px", height: "100px", borderRadius: '50px'}} src={URL.createObjectURL(productImage.image)} alt="product"/>
                       :

                       <img style={{width: "100px", height: "100px", borderRadius: '50px'}} src={payment.profile} alt="product"/>

                       
                        }


                <label htmlFor="input" className="prescription-btn">Image</label>
                <input onChange={onFileChange} type="file" id="input" style={{display: 'block'}} />
              </div>
              <div style={{color: 'red'}}>
                {(error !== '' && error.product_image)}
                </div>
            </div> */}

            

            <div style={{color: 'red', marginTop: '20px', textAlign: 'center'}}>
                {(generalError !== '' && generalError.message || generalError) }
              </div>
                    
                  {successMessage !== null &&
                    <div style={{color: 'green', marginTop: '20px', textAlign: 'center'}}>
                    {successMessage}
                  </div>
                  }
              


              <div className="sign-botton">
                <a onClick={postProduct} className="sign-up-btn" style={{cursor: 'pointer', color: 'white'}}>
                  
                  {isSendingRequest ? 'Uploading...': 'Upload payment'}
                  
                </a>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
}
  </div>
</div>

  )
}

export default EditPayment
