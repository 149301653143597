import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cart: JSON.parse(localStorage.getItem('cart')) || [],
    product: JSON.parse(localStorage.getItem('product')) || null,
    items: JSON.parse(localStorage.getItem('plate')) || [] 


}

export const paymentSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {

        setProduct: (state, action) => {
            state.product = action.payload;
            localStorage.setItem('product', JSON.stringify(state.product));
        },


        addItem: (state, action) =>{

            if (!state.items.filter(item => item.created_at === action.payload.created_at).length > 0) {
                state.items.push(action.payload);
                localStorage.setItem('plate', JSON.stringify(state.items));

            }            
            
        },

        removeItem: (state, action) => {
            state.items = state.items.filter(item => item.created_at !== action.payload.created_at);
            localStorage.setItem('plate', JSON.stringify(state.items));

       } 

    }
})

export const {setProduct, addItem, removeItem} = paymentSlice.actions;
export default paymentSlice.reducer;