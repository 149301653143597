import React from 'react'
import { useSelector } from 'react-redux'
import ListDoctors from '../components/Doctors/ListDoctors';
import Footer from '../layouts/Footer';
import { Link } from 'react-router-dom';

const Index = () => {

  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

  const user = useSelector((state) => state.user.user);

  return (
   
             <div className="home-contener" style={{marginTop: '50px'}}>
               <div className="home-banner">
                 <div className="swiper-container swiper-init swiper-container-horizontal">
                   <div className="swiper-wrapper">
                     <div className="swiper-slide">
                       <div className="content">
                         <img src="images/main-banner.png" alt />
                         <div className="home-banner-text">
                           <h2>{user.balance === null ? formatPrice(0):formatPrice(user.balance) }</h2>
                           <p>Total Store Credit</p>
                           <div className="order-now-btn">
                             <Link className="home-order-now" to="/payment">Make Deposit</Link>
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
                 <div className="services-section specialties-section">
                   <div className="special-title">
                     <h2>Specialties</h2>
                     <Link to="/doctors" className="view-all-btn">View All</Link>
                   </div>
                   <div className="special-main">
                     <div className="special-box">
                       <div className="special-text-box cardiology">
                         <span><Link to="/doctors">Cardiology</Link></span>
                       </div>
                     </div>
                     <div className="special-box">
                       <div className="special-text-box gastrology2">
                         <span><Link to="/doctors">Cardiology</Link></span>
                       </div>
                     </div>
                     <div className="special-box">
                       <div className="special-text-box neurology">
                         <span><Link to="/doctors">Neurology</Link></span>
                       </div>
                     </div>
                   </div>
                 </div>
                 <div className="services-section">
                   <h2>Services</h2>
                   <div className="services-box">
                     <ul className="services-item-box">
                      <Link to="/doctors" className="services-item">
                         <div className="services-text doctor">
                           <span><Link to="/doctors">Doctors</Link></span>
                         </div>
                       </Link>
                       <Link to="/pharmacy" className="services-item">
                         <div className="services-text pharmacy">
                           <span><Link to="/pharmacy">Pharmacy</Link></span>
                         </div>
                       </Link>
                       <Link to="/pills-reminder" className="services-item">
                         <div className="services-text reminder">
                           <span><Link to="/pills-reminder">Pill Reminder</Link></span>
                         </div>
                       </Link>
                       <Link to="/ambulances" className="services-item">
                         <div className="services-text ambulance">
                           <span><Link to="/ambulances">Ambulance</Link></span>
                         </div>
                       </Link>
                       
                       <Link to="/list-test" className="services-item">
                         <div className="services-text diagnostic">
                           <span><Link to="/list-test">Diagnostic</Link></span>
                         </div>
                       </Link>
                     </ul>
                   </div>
                 </div>  


                 <ListDoctors/>

               </div>
             </div>

 
  )
}

export default Index
