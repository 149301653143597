import React, {useState} from 'react'
import apiClient from '../../Request/http-common';
import { useMutation } from "react-query";

const UploadDoctor = () => {

  const initialLogin = {
    name: '',
    category: '',
    price: '',
    time: '',
    experience: '',
    status: '',
    description: '',

}

  const [error, setErros] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [productForm, setproductForm] = useState(initialLogin);
  const [successMessage, setSuccessMessage] = useState(null);
  const onChange = (e) =>
    setproductForm({ ...productForm, [e.target.name]: e.target.value });

  const { isLoading: isSendingRequest, mutate: postProduct } = useMutation(
    async () => {
        const formData = new FormData();
        formData.append('name', productForm.name);
        formData.append('category', productForm.category);
        formData.append('price', productForm.price);
        formData.append('time', productForm.time);
        formData.append('experience', productForm.experience);
        formData.append('description', productForm.description);
        formData.append('status', productForm.status);
        formData.append('profile', productImage.image);

      return await apiClient.post(`/api/store-doctor`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      onSuccess: (res) => {
        if (res.data.status === 'success') {
          setSuccessMessage(res.data.message);
        }
      },
      onError: (err) => {   
        if (err.response?.data.hasOwnProperty('errors')) {
          let myerror = err.response?.data.errors || err;         
          setErros(myerror)   
                   
      }else{
          let errorMessage = err.response?.data.message || err ;
          setGeneralError(errorMessage);

          
      }       
             
      },
    }
  );


  const initialImage = {
    image: ''
}
const [productImage, setImage] = useState(initialImage);
const  onFileChange = (event) => { 
    setImage({...productImage, image: event.target.files[0]});
  }; 

  return (
    <div className="main-container" style={{backgroundColor: '#fff'}}>
  <div id="app">
    <div className="page">
      <div className="page-content">
        <div className="sign-in-page segments">
          {/* <div className="navbar sign-header">
            <div className="navbar-inner">
              <div className="back-link">
                <a className="link back" href="index.html">
                  <img className="back-icon" src="images/back-icon.svg" alt />
                </a>
              </div>
            </div>
          </div> */}
          <div className="sign-up-section">
            <div className="sign-up-top">
              <h2>Add New Doctor</h2>
              <p>fill the form to upload new Doctor</p>
            </div>
            <div className="sign-up-form">
              <div className="sign-form-fild">
                <label>Name</label>
                <input onChange={onChange} name='name' type="text" placeholder="Lucy Martin" />   
                <div style={{color: 'red'}}>
                {(error !== '' && error.name)}
                </div>
              </div>
              <div className="sign-form-fild">
                <label>category</label>
                <input onChange={onChange} name='category' type="text" placeholder="cardiology" /> 
                <div style={{color: 'red'}}>
                {(error !== '' && error.category)}
                </div>  
              </div>
              <div className="sign-form-fild">
                <label>Price</label>
                <input onChange={onChange} name='price' type="number" placeholder='2000' />  
                <div style={{color: 'red'}}>
                {(error !== '' && error.price)}
                </div>
              </div>

              <div className="sign-form-fild">
                <label>time</label>
                <input onChange={onChange} name='time' type="text" placeholder='2000' /> 
                <div style={{color: 'red'}}>
                {(error !== '' && error.time)}
                </div> 
              </div>

              <div className="sign-form-fild">
                <label>experience</label>
                <input onChange={onChange} name='experience' type="text" placeholder='2000' />  
                <div style={{color: 'red'}}>
                {(error !== '' && error.experience)}
                </div>
              </div>

              <div className="sign-form-fild">
                <label>status</label>
                <input onChange={onChange} name='status' type="text" placeholder='2000' /> 
                <div style={{color: 'red'}}>
                {(error !== '' && error.status)}
                </div> 
              </div>

              <div className="sign-form-fild">
                <label>description</label>
                <textarea style={{width: '100%', height: '120px'}} onChange={onChange} name='description' type="text" placeholder='write...' >
                </textarea>
                <div style={{color: 'red'}}>
                {(error !== '' && error.description)}
                </div> 
              </div>

             <div className="clinic-main">
             
              <div className="charges-details text-center">
              {productImage.image !== '' &&
                            <img style={{width: "100px", height: "100px", borderRadius: '50px'}} src={URL.createObjectURL(productImage.image)} alt="product"/>
                        }
                <label htmlFor="input" className="prescription-btn">Image</label>
                <input onChange={onFileChange} type="file" id="input" style={{display: 'none'}} />
              </div>
              <div style={{color: 'red'}}>
                {(error !== '' && error.product_image)}
                </div>
            </div>

            

            <div style={{color: 'red', marginTop: '20px', textAlign: 'center'}}>
                {(generalError !== '' && generalError.message || generalError) }
              </div>
                    
                  {successMessage !== null &&
                    <div style={{color: 'green', marginTop: '20px', textAlign: 'center'}}>
                    {successMessage}
                  </div>
                  }
              


              <div className="sign-botton">
                <a onClick={postProduct} className="sign-up-btn" style={{cursor: 'pointer'}}>
                  
                  {isSendingRequest ? 'Uploading...': 'Upload Doctor'}
                  
                </a>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default UploadDoctor
