import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    ambulance: JSON.parse(localStorage.getItem('ambulance')) || [],
    appointment: JSON.parse(localStorage.getItem('appointment')) || [],
    diagnostic: JSON.parse(localStorage.getItem('diagnostic')) || [],
    pharmacy: JSON.parse(localStorage.getItem('pharmacy')) || [],
    pill_reminder: JSON.parse(localStorage.getItem('pill_reminder')) || [],
    doctors: JSON.parse(localStorage.getItem('doctors')) || [],
    payment_methods: JSON.parse(localStorage.getItem('payment_methods')) || [],

    
}

export const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        setAmbulance: (state, action) => {
            state.ambulance = action.payload;  
            localStorage.setItem('ambulance', JSON.stringify(state.ambulance));
        },

        setAppointment: (state, action) => {
            state.appointment = action.payload;  
            localStorage.setItem('appointment', JSON.stringify(state.appointment));
        },

        setDiagnostic: (state, action) => {
            state.diagnostic = action.payload;  
            localStorage.setItem('diagnostic', JSON.stringify(state.diagnostic));
        },

        setPharmacy: (state, action) => {
            state.pharmacy = action.payload;  
            localStorage.setItem('pharmacy', JSON.stringify(state.pharmacy));
        },

        setPillReminder: (state, action) => {
            state.pill_reminder = action.payload;  
            localStorage.setItem('pill_reminder', JSON.stringify(state.pill_reminder));
        },

        setDoctors: (state, action) => {
            state.doctors = action.payload;  
            localStorage.setItem('doctors', JSON.stringify(state.doctors));
        },

        setPaymentMethods: (state, action) => {
            state.payment_methods = action.payload;  
            localStorage.setItem('payment_methods', JSON.stringify(state.payment_methods));
        },

        

    }
})

export const {setAmbulance, setAppointment, setDiagnostic, setPharmacy, setPillReminder, setDoctors, setPaymentMethods } = historySlice.actions;
export default historySlice.reducer;


