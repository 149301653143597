import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div style={{position: 'fixed', zIndex: 200, bottom: 0, width: '100%', marginTop: '100px'}}>
      <div className="footer toolbar toolbar-bottom">
           <div className="toolbar-inner">
             <Link to="/" className="tab-link home tab-link-active">Home</Link>
             <Link to="/doctors" className="tab-link search">Doctor</Link>
             <Link to="/pharmacy" className="tab-link wishlist">Pharmacy</Link>
             <Link to="/list-test" className="tab-link cart">Diagnostic</Link>
             <Link to="/profile" className="tab-link my-account">Patient Details</Link>
           </div>
         </div>
    </div>
  )
}

export default Footer
