
import axios from "axios";
import { useSelector } from "react-redux";

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

const access_token = localStorage.getItem('auth-token');

export default axios.create({

  baseURL: 'https://api.onlinecarepharmacy.com',
  
  headers: {
    "Content-type": "application/json",
    withCredentials: true,
  }
});