import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ListAdminPaymentMethods = () => {

  const payments = useSelector((state) => state.admin.adminpayment_methods);  
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

  return (
    <div style={{marginTop: '70px', padding: '10px'}} className="">
      <div className='card' style={{boxShadow: '2px 5px 4px 0px gray', backgroundColor: 'white', color: 'black'}}>
        {payments.length > 0 &&
        <div className='table-responsive'>
         <table className="table special-box responsive">

         <thead>
           <tr>
             <th scope="col">Name</th>
             <th scope="col">Details</th>
             <th scope="col">edit</th>
           </tr>
         </thead>

         <tbody>
           
            {payments.map(payment => (
              <tr key={payment.id}>
              <td>{payment.name}</td>
                <td>{payment.details}</td>
                <td><Link to={`/view-payment-method/${payment.id}`} className='btn btn-secondary pill'>Edit</Link></td>
              </tr>
            ))}             

         </tbody>

       </table>
       </div>
        }
       
        </div>
    </div>
  )
}

export default ListAdminPaymentMethods
