import React from 'react'
import { useSelector } from 'react-redux'
import PaymentButton from '../Payment/PaymentButton';
import Footer from '../../layouts/Footer';
const ListTest = () => {
    const diagnostics = useSelector((state) => state.history.diagnostic);
const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
  return (
    <div>
     <div className="appointment-details" style={{marginTop: '40px'}}>
              {diagnostics.length > 0 &&
                <div className="medicine-cart lab-test-lists">
                    {diagnostics.map(test=>(
                        <div className="medicine-product">
                            <div className="medicine-left"><a href="lab-test-details-description.html"><img src="images/icon-pic.png" alt /></a></div>
                            <div className="medicine-right">
                            <h2><a href="">{test.title}</a></h2>
                            {/* <p><strong>{test.description}</strong></p> */}
                            <div className="medicine-price-box">
                                <div className="price-left">
                                <strong>{formatPrice(test.amount)}</strong>
                                <span className="save-text">{test.status}</span>
                                </div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div className="select-button"><a href={test.report}>Result</a></div>
                            <PaymentButton product={test} name="Pay Now" />

                            </div>
                            
                            </div>
                        </div>
                    ))}              
                </div>
                }
            </div>
    </div>
  )
}

export default ListTest
