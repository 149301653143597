import React from 'react'
import { useSelector } from 'react-redux'
import { setSideBar } from '../components/Auth/userSlice';
import { useDispatch } from 'react-redux';
const Header = () => {
    const dispatch = useDispatch();
const user = useSelector((state) => state.user.user);
const sidebar = useSelector((state) => state.user.sidebar);

  return (
    <div className="home-top-bar" style={{position: 'fixed', height: 'fit-content', zIndex: 200000, top: 0, width: '100%', backgroundColor: 'white'}}>
        <div className="bell-icon"
        
        style={{marginRight: '10%', backgroundImage: sidebar === 'close'? 'url(https://res.cloudinary.com/dbbxcbw61/image/upload/v1725671289/menu_1_k3ch4z.png)' : 'url(https://res.cloudinary.com/dbbxcbw61/image/upload/v1725671289/menu_osmeaw.png)', height: '48px', width: '48px'}}
        onClick={()=>
        {
            if (sidebar === 'close') {
                dispatch(setSideBar('open')) 
            }else{
                dispatch(setSideBar('close'))
            }
            
        }
            }
        ></div>

        <div className="home-top-text" >
        {/* <span>Hi, {user.name}</span> */}
        <img style={{height: '50px'}} src='https://res.cloudinary.com/dbbxcbw61/image/upload/v1724031661/onlinepahamabrowser_fbfd17.png'/>
        </div>

        
    </div>
  )
}

export default Header
