import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: JSON.parse(sessionStorage.getItem('user')) || {},
    sidebar: localStorage.getItem('sidebar') || '',
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) =>{
            
            state.user = action.payload;  
            sessionStorage.setItem('user', JSON.stringify(state.user));
        },

        setSideBar: (state, action) =>{
            
            state.sidebar = action.payload;  
            localStorage.setItem('sidebar', state.sidebar);
        }
    }
})

export const {setUser, setSideBar} = userSlice.actions;
export default userSlice.reducer;