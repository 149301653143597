import React, {useEffect} from 'react'
import apiClient from '../http-common';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { setAmbulance, setAppointment, setDiagnostic, setPharmacy, setPillReminder, setDoctors, setPaymentMethods } from './historySlice';
export const LoadHistory = () => {

  const dispatch = useDispatch();

    const { isLoading: isLoadingHistory, refetch: getHistory } = useQuery(
        "history",
        async () => {
          return await apiClient.get("/api/history");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
              dispatch(setAmbulance(res.data.ambulance))
              dispatch(setAppointment(res.data.appointment))
              dispatch(setDiagnostic(res.data.diagnostic))
              dispatch(setPharmacy(res.data.pharmacy))
              dispatch(setPillReminder(res.data.pill_reminder))
              dispatch(setDoctors(res.data.doctors))
              dispatch(setPaymentMethods(res.data.payment_methods))
              

            }
          },
          onError: (err) => {
            console.log(err.response?.data || err);
          },
        }
      );

      useEffect(() => {
        getHistory();
      }, []);
 
}

export default LoadHistory
