import logo from './logo.svg';
import './App.css';
import Index from './Pages/Index';
import LoadHistory from './Request/History/LoadHistory';
import { Routes, Route } from "react-router-dom";
import UploadDoctor from './components/Admin/UploadDoctor';
import Register from './components/Auth/Register';
import { useSelector } from 'react-redux';
import { Fragment } from 'react';
import CheckAuth from './components/Auth/checkAuth';
import Login from './components/Auth/Login';
import UploadPharmacy from './components/Admin/UploadPharmacy';
import UploadPillsReminder from './components/Admin/UploadPillsReminder';
import UploadDiagnostic from './components/Admin/UploadDiagnostic';
import UploadAmbulance from './components/Admin/UploadAmbulance';
import ListPharmacy from './components/Pharmacy/ListPharmacy';
import ListTest from './components/Diagnostic/ListTest';
import AllDoctors from './components/Doctors/AllDoctors';
import ListReminder from './components/PillReminder/ListReminder';
import ListAmbulance from './components/Ambulance/ListAmbulance';
import Cart from './components/Payment/Cart';
import UploadPayment from './components/Admin/UploadPayment';
import Header from './layouts/Header';
import NavBar from './layouts/NavBar';
import Footer from './layouts/Footer';
import { useDispatch } from 'react-redux';
import { setSideBar } from './components/Auth/userSlice';
import Profile from './components/Patients/Profile';
import Payment from './components/Payment/Payment';
import ListPayments from './components/Payment/ListPayments';
import LabResult from './components/Diagnostic/LabResult';
import AdminHistory from './Request/History/AdminHistory';
import ListAdminAmbulance from './components/Admin/ListAmbulance';
import EditAmbulance from './components/Admin/EditAmbulance';
import ListAdminDignostic from './components/Admin/ListDiagnostic';
import EditDiagnostic from './components/Admin/EditDignostic';
import ListAdminDoctor from './components/Admin/ListDoctor';
import EditDoctor from './components/Admin/EditDoctor';
import ListAdminPharmacy from './components/Admin/ListPharmacy';
import EditPharmacy from './components/Admin/EditAdminPharmacy';
import EditPillsReminder from './components/Admin/EditPillReminder';
import ListAdminPillReminder from './components/Admin/ListPillReminder';
import ListAdminPayments from './components/Admin/ListPayment';
import EditPayment from './components/Admin/EditPayment';
import ListAdminPaymentMethods from './components/Admin/ListPaymentMethod';
import EditPaymentMethod from './components/Admin/EditPaymentMethod';
import ListAdminUsers from './components/Admin/ListUsers';
import EditUser from './components/Admin/EditUser';
import MissingRoute from './components/MissingRoute';

function App() {

  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const sidebar = useSelector((state) => state.user.sidebar);
  const user = useSelector((state) => state.user.user);


  return (
    <>
    <CheckAuth/>
    
    
    
    
      {isAuth ?
      <Fragment>
        {user.is_admin === 'admin' && <AdminHistory/>}
        <LoadHistory/>
        <div className="main-container" 
        onClick={()=> sidebar !== 'close'&& dispatch(setSideBar('close'))}
        
        >
          <div id="app">
            <div className="view view-main view-init ios-edges">
              <div className="page page-home page-with-subnavbar home-main-page">
                <div className="tabs">
                  <div id="tab-home" className="tab tab-active tab-home">
                    {/* home */}
                    <Header/>
                    <NavBar/>

        <Routes> 
        <Route path='/' element={<Index/>} />
        <Route path='/register' element={<Index/>} />
        <Route path='/login' element={<Index/>} />
        <Route path='/upload-doctor' element={<UploadDoctor/>} />
        <Route path='/upload-pharmacy' element={<UploadPharmacy/>} />
        <Route path='/upload-pill' element={<UploadPillsReminder/>} />
        <Route path='/upload-diagnostics' element={<UploadDiagnostic/>} />
        <Route path='/upload-ambulance' element={<UploadAmbulance/>} />
        <Route path='/pharmacy' element={<ListPharmacy/>} />
        <Route path='/list-test' element={<ListTest/>} />
        <Route path='/doctors' element={<AllDoctors/>} />
        <Route path='/pills-reminder' element={<ListReminder/>} />
        <Route path='/ambulances' element={<ListAmbulance/>} />
        <Route path='/cart' element={<Cart/>} />
        <Route path='/upload-payment' element={<UploadPayment/>} />
        <Route path='/profile' element={<Profile/>} />
        <Route path='/payment' element={<Payment/>} />
        <Route path='/lab-result' element={<LabResult/>} />
        <Route path='/list-payments' element={<ListPayments/>} />
        <Route path="*" element={<MissingRoute/>} />







        {/* admin routes */}
        <Route path='/list-admin-ambulance' element={<ListAdminAmbulance/>} />
        <Route path='/view-ambulance/:id' element={<EditAmbulance/>} />

        <Route path='/list-admin-dignostics' element={<ListAdminDignostic/>} />
        <Route path='/view-dignostic/:id' element={<EditDiagnostic/>} />

        <Route path='/list-admin-doctors' element={<ListAdminDoctor/>} />
        <Route path='/view-doctor/:id' element={<EditDoctor/>} />

        <Route path='/list-admin-pharmacy' element={<ListAdminPharmacy/>} />
        <Route path='/view-pharmacy/:id' element={<EditPharmacy/>} />

        <Route path='/list-admin-pills' element={<ListAdminPillReminder/>} />
        <Route path='/view-pill/:id' element={<EditPillsReminder/>} />

        <Route path='/list-admin-payments' element={<ListAdminPayments/>} />
        <Route path='/view-payment/:id' element={<EditPayment/>} />

        <Route path='/list-admin-payment-method' element={<ListAdminPaymentMethods/>} />
        <Route path='/view-payment-method/:id' element={<EditPaymentMethod/>} />

        
        <Route path='/list-admin-users' element={<ListAdminUsers/>} />
        <Route path='/view-user/:id' element={<EditUser/>} />        
        </Routes> 

                </div>
                </div>
                
              </div>
            </div>
          </div>
          <div className='' style={{height: '70px'}}>

          </div>
          <Footer/>
        </div>
      </Fragment>
      
      
    :
    <Fragment>
      <Routes>
      <Route path='/' element={<Login/>} />
      <Route path='/login' element={<Login/>} />
      <Route path='/register' element={<Register/>} />
      <Route path="*" element={<MissingRoute/>} />
      </Routes>
    </Fragment>
    
    }
    
    
    
    </>
  );
}

export default App;
