import {useEffect} from 'react'
import apiClient from '../../Request/http-common';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { setAuthStatus } from './authSlice';
import { useSelector } from 'react-redux';

import { setToken } from './authSlice';
import { setUser } from './userSlice';

export const CheckAuth = () => {
    const isAuth = useSelector((state) => state.auth.isAuthenticated);
    const token = useSelector((state) => state.auth.authToken); 

    const dispatch = useDispatch();
    const { isLoading: ischeckingAuth, refetch: checkAuth } = useQuery(
        "check-auth",
        async () => {
            return await apiClient.get("/api/check-auth");  
          
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                dispatch(setAuthStatus(res.data.message))
                if (res.data.message === 'authenticated') {
                  dispatch(setUser(res.data.user));
                }
                               
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
            dispatch(setAuthStatus('unauthenticated'))
            localStorage.removeItem('user');
          },
        }
      );

      useEffect(() => {
        checkAuth();
      }, []);
 
}

export default CheckAuth
