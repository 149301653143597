import React from 'react'
import { useSelector } from 'react-redux'
import PaymentButton from '../Payment/PaymentButton';
import Footer from '../../layouts/Footer';
const ListAmbulance = () => {
    const ambulances = useSelector((state) => state.history.ambulance);
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

  return (
    <div>
      <div className="specialties-main">
                        
                        <div className="tabs-bottom-section">
                        <div id="All" className="tabs-container city">
                            <div className="tabs-product-section">
                            {ambulances.map(ambulance => (
                                 <div key={ambulance.id} className="tabs-product-main">
                                 <div className="tabs-product-box">
                                 <div className="tabs-product-left"><a href=""><img src="https://res.cloudinary.com/dzyhsongt/image/upload/v1724859077/call-ambulance-img_fiq8mq.png" style={{width: '150px',  borderRadius: '5px'}} /></a></div>
                                 <div className="tabs-product-right">
                                     <h2><a href="">{ambulance.location}</a></h2>
                                     <div className="">{ambulance.status}</div>
                                     <div className="price-exp-box">
                                     <span>{formatPrice(ambulance.amount)}</span>
                                     {/* <span className="heart">251</span> */}
                                     </div>
                                 </div>
                                 </div>
                                 <div className="appointment-box">
                                 
                                 <div className="appointment-right">
                                     <PaymentButton product={ambulance} name="Pay Now" />
                                 </div>
                                 </div>
                             </div>
                            ))}
                            </div>
                        </div>
                    </div>
                       
                    </div>

    </div>
  )
}

export default ListAmbulance
