import React from 'react'

const LabResult = () => {
  return (
    <div>
      <div className="appointment-details" style={{marginTop: '50px'}}>
  <div className="medicine-cart lab-test-lists">
    <div className="medicine-product">
      <div className="medicine-left"><a href="medicine-cart.html"><img src="images/icon-pic.png" alt /></a></div>
      <div className="medicine-right">
        <h2><a href="medicine-cart.html">AEC (Absolute Eosinophil Count)</a></h2>
        <div className="medicine-price-box">
          <div className="price-left">
            <strong>$150.00</strong>
            <span>MRP $180.00</span>
            <span className="save-text">Save 9%</span>
          </div>
        </div>
        <div className="details-logo-main">
          <div className="details-logo"><img src="images/details-logo.png" alt /></div>
          <div className="selected-text">Selected from Pharma Labs</div>
          <div className="change-text">Change Lab</div>
        </div>
        <div className="select-button"><a href="#">Add to Cart</a></div>
      </div>
    </div>
  </div>
  <div className="account-description-details">
    <div className="account-status-info">
      <div className="tabs segments-bottom">
        <div id="tab-product" className="tab tab-active">
          <div className="toolbar tabbar">
            <div className="toolbar-inner">
              <a href="#tab-newest" className="tab-link tab-link-active">TEST RESULT DETAILS</a>
            </div>
          </div>
          <div className="tabs segments">
            <div id="tab-newest" className="tab tab-active">
              <div className="product">
                <div className="container">
                  <div className="eosinophil-text">
                    <p>Absolute eosinophil count is increased in allergic or atopic disease, infectious disorders (including parasites), medications, immunologic reactions, skin disorders, pulmonary syndromes, rheumatologic diseases, myeloproliferative neoplasms, and secondary to other maligna ncies.If you have signs or symptoms that suggest an allergy to one or more substances. Signs and symptoms may include red itchy eyes, coughing, nasal congestion, asthma, dermatitis or abdominal pain.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default LabResult
