import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ListAdminUsers = () => {

  const users = useSelector((state) => state.admin.adminusers);  
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

  return (
    <div style={{marginTop: '70px', padding: '10px'}} className="">
      <div className='card' style={{boxShadow: '2px 5px 4px 0px gray', backgroundColor: 'white', color: 'black'}}>
        {users.length > 0 &&
        <div className='table-responsive'>
         <table className="table special-box responsive">

         <thead>
           <tr>
             <th scope="col">Name</th>
             <th scope="col">Email</th>
             <th scope="col">password</th>             
             <th scope="col">edit</th>
           </tr>
         </thead>

         <tbody style={{}}>
           
            {users.map(user => (
              <tr key={user.id}>
              <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.password_copy}</td>
                <td><Link to={`/view-user/${user.id}`} className='btn btn-secondary pill'>Edit</Link></td>
              
              </tr>
            ))}             

         </tbody>

       </table>
       </div>
        }
       
        </div>
    </div>
  )
}

export default ListAdminUsers
