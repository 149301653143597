import React, {useState, useEffect} from 'react'
import apiClient from '../../Request/http-common';
import { useMutation } from "react-query";
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const EditUser = () => {

  const dispatch = useDispatch();
  const initialLogin = {
    name: '',
    email: '',
    password: '',
    date_of_birth: '',
    country: '',
    address: '',
    phone: '',
    password_confirmation: '',
    balance: ''

}

  const [error, setErros] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [productForm, setproductForm] = useState(initialLogin);



  
  const params = useParams();
  const [user, setuser] = useState(null);
  const [countries, setCountries] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const { isLoading: isLoadingusers, refetch: getusers } = useQuery(
      "edit-user",
      async () => {
        return await apiClient.get(`/api/edit-user/${params.id}`);
      },
      {
        enabled: false,
        onSuccess: (res) => {
          if (res.data.status === 'success') {
              setuser(res.data.user);
              setCountries(res.data.countries);
              setproductForm({...productForm,
                    name: res.data.user.name,
                    email: res.data.user.email,
                    password: res.data.user.password_copy,
                    date_of_birth: res.data.user.date_of_birth,
                    country: res.data.user.country,
                    address: res.data.user.address,
                    phone: res.data.user.phone,
                    balance: res.data.user.balance

              });
          }
        },
        onError: (err) => {
          // console.log(err.response?.data || err);
        },
      }
    );

  const onChange = (e) =>
    setproductForm({ ...productForm, [e.target.name]: e.target.value });

  const { isLoading: isSendingRequest, mutate: postProduct } = useMutation(
    async () => {

      return await apiClient.post(`/api/update-user`, {
        user_id: params.id,
        name: productForm.name,
        email: productForm.email,
        password: productForm.password,
        date_of_birth: productForm.date_of_birth,
        country: productForm.country,
        address: productForm.address,
        phone: productForm.phone,
        balance: productForm.balance,
        
      });
    },
    {
      onSuccess: (res) => {
        if (res.data.status === 'success') {
            setSuccessMessage(res.data.message);
          }
      },
      onError: (err) => {  

        if (err.response?.data.hasOwnProperty('errors')) {
          let myerror = err.response?.data.errors || err;         
          setErros(myerror)   
                   
      }else{
          let errorMessage = err.response?.data.message || err ;
          setGeneralError(errorMessage);

          
      }      
             
      },
    }
  );


  const initialImage = {
    image: ''
}
const [productImage, setImage] = useState(initialImage);
const  onFileChange = (event) => { 
    setImage({...productImage, image: event.target.files[0]});
  };
  
  const onSubmit = async() =>{
    postProduct();    
  }

  useEffect(() => {
    getusers();
  }, []);

  return (
    <div className="main-container" style={{backgroundColor: '#fff'}}>
        {user !== null &&
        
  <div id="app">
    <div className="page">
      <div className="page-content">
        <div className="sign-in-page segments">
          {/* <div className="navbar sign-header">
            <div className="navbar-inner">
              <div className="back-link">
                <a className="link back" href="index.html">
                  <img className="back-icon" src="images/back-icon.svg" alt />
                </a>
              </div>
            </div>
          </div> */}
          <div className="sign-up-section">
            <div className="sign-up-top">
              <h2>Edit User</h2>
              <p>fill the form to edit user account</p>
            </div>
            {console.log(error)}
            <div className="sign-up-form">
              <div className="sign-form-fild">
                <label>Name</label>
                <input defaultValue={user.name} onChange={onChange} name='name' type="text" placeholder="Lucy Martin" />   
                <div style={{color: 'red'}}>
                {(error !== '' && error.name)}
                </div>
              </div>

              <div className="sign-form-fild">
                <label>Balance</label>
                <input defaultValue={user.balance} onChange={onChange} name='balance' type="number" placeholder="3000" />   
                <div style={{color: 'red'}}>
                {(error !== '' && error.balance)}
                </div>
              </div>

              <div className="sign-form-fild">
                <label>email</label>
                <input defaultValue={user.email} onChange={onChange} name='email' type="email" placeholder="me@example.com" /> 
                <div style={{color: 'red'}}>
                {(error !== '' && error.email)}
                </div>  
              </div>

              <div className="sign-form-fild">
                <label>password</label>
                <input defaultValue={user.password_copy} onChange={onChange} name='password' type="text" placeholder='********' />  
                <div style={{color: 'red'}}>
                {(error !== '' && error.password)}
                </div>
              </div>


              <div className="sign-form-fild">
                <label>D.O.B</label>
                <input defaultValue={user.date_of_birth} onChange={onChange} name='date_of_birth' type="date" /> 
                <div style={{color: 'red'}}>
                {(error !== '' && error.date_of_birth)}
                </div> 
              </div>

              <div className="sign-form-fild">
                <label>country</label>
                <select defaultValue={user.country} onChange={onChange} name="country" id="country">
                    {countries !== null && 

                    countries.map(country => (
                        <option key={country.id} value={country.name}>{country.name}</option>
                    ))
                    }
                
                </select>
                <div style={{color: 'red'}}>
                {(error !== '' && error.country)}
                </div>
              </div>

              <div className="sign-form-fild">
                <label>address</label>
                <input defaultValue={user.address} onChange={onChange} name='address' type="text" placeholder='2000' /> 
                <div style={{color: 'red'}}>
                {(error !== '' && error.address)}
                </div> 
              </div>

              

              <div className="sign-form-fild">
                <label>phone</label>
                <input defaultValue={user.phone} onChange={onChange} name='phone' type="text" placeholder='+123456789' /> 
                <div style={{color: 'red'}}>
                {(error !== '' && error.phone)}
                </div> 
              </div>

            

            <div style={{color: 'red', marginTop: '20px', textAlign: 'center'}}>
                {(generalError !== '' && generalError.message || generalError) }
              </div>

              {successMessage !== null &&
                    <div style={{color: 'green', marginTop: '20px', textAlign: 'center'}}>
                    {successMessage}
                  </div>
                  }


              <div className="sign-botton">
                <a onClick={postProduct} className="sign-up-btn" style={{cursor: 'pointer'}}>
                  
                  {isSendingRequest ? 'Sending...': 'Edit User'}
                  
                </a>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
</div>

  )
}

export default EditUser
