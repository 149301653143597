import React from 'react'
import { setProduct, addItem } from './paymentSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const PaymentButton = (props) => {
  const {name, product} = props
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
      <div onClick={()=>{dispatch(addItem(product))
        setTimeout(() => {
          navigate('/cart')
        }, 1000);
        
      }} className= {name ==='Appointment' ? 'appoint': 'home-star bg-red appoint select-button'} style={{ cursor: 'pointer'}}>
       <a href="#" style={{color: '#fff'}}>{name}</a> 
    </div>
  )
}

export default PaymentButton
