import React, {useState, Fragment} from 'react'
import { useSelector } from 'react-redux';


import apiClient from '../../Request/http-common';
import { useMutation } from "react-query";
import { useNavigate } from 'react-router-dom';

const Payment = () => {
    const navigate= useNavigate();
    const [amount, setAmount] = useState(0);
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

    const paymentMethods = useSelector((state) => state.history.payment_methods);
    const product = useSelector((state) => state.cart.product);
    const [payment, setPayment] = useState(null);
    const setPaymentId = (id)=>{
        paymentMethods.forEach(payment => {
          if (parseInt(id) === parseInt(payment.id)) {
            setPayment(payment);
          }
        });
      }


      
  const initialLogin = {
    name: '',
    details: '',

}

  const [error, setErros] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [productForm, setproductForm] = useState(initialLogin)
  const [successMessage, setSuccessMessage] = useState(null);

      const { isLoading: isSendingRequest, mutate: postProduct } = useMutation(
        async () => {
            const formData = new FormData();
            formData.append('amount', amount);
            formData.append('method', payment.id);
            formData.append('description', 'Bill Payment');
    
          return await apiClient.post(`/api/store-payment`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        },
        {
          onSuccess: (res) => {
            if (res.data.status === 'success') {
              setSuccessMessage(res.data.message);
              setTimeout(() => {
                navigate('/list-payments')
              }, 2000);
            }
          },
          onError: (err) => {   
            if (err.response?.data.hasOwnProperty('errors')) {
              let myerror = err.response?.data.errors || err;         
              setErros(myerror)   
                       
          }else{
              let errorMessage = err.response?.data.message || err ;
              setGeneralError(errorMessage);
    
              
          }       
                 
          },
        }
      );
  return (
    <div>
     <div className="medicine-details-main details-text" style={{marginTop: '40px'}}>
            
            <div className="payment-summary">
              <h2>Make Payment</h2>
              <div className="summary-text-box">

             

                <div className=''>

                <label for="amount" style={{color: 'black', fontSize: '14px', fontWeight: 'bolder'}}>Amount To Pay</label>
                <input id='amount'  style={{border: '1px solid black', width: '100%', height: '40px', marginBottom: '10px'}}  onChange={(e)=> setAmount(e.target.value)}  name='amount' type="number" placeholder="2000" /> 
                
                <select onChange={(e)=>setPaymentId(e.target.value)} name='payment_method' style={{border: '1px solid black', width: '100%', height: '40px'}}
                  
                  >
                    <option>
                      Select Payment Method
                    </option>
                    {paymentMethods.length > 0 &&
                    paymentMethods.map(method => (
                      <option key={method.id} value={method.id}
                      >
                        {method.name}
                      </option>
                    ))
                    
                    }
                  </select>
                </div>
                    {console.log(payment)}
                    {payment !== null &&
                    <Fragment>
                      <div className="summary-text">
                        <strong>Payment Method</strong>
                        <span>{payment.name}</span>
                      </div>

                      <div className="summary-text">
                        {/* <strong>{payment.name}</strong> */}
                        <span>{payment.details}</span>
                      </div>
                      
                    </Fragment>
                    
                    }               
               
              </div>
              {payment != null &&
                <p style={{color: 'green', textAlign: 'center', marginTop: '25px'}}>
                Kindly use the above details to make payment
                </p>
                }
              <div className="cart-total-price">
                
                  
                <strong>Total</strong>
                <strong>{formatPrice(amount)}</strong>
              </div>
            </div>
            <div className="checkout-btn" onClick={postProduct}>
              <a href="#" className="checkout"> {isSendingRequest ? 'processing...' : 'Confirm Payment'}</a>
            </div>
          </div>
    </div>
  )
}

export default Payment
