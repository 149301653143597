import React from 'react'
import { useSelector } from 'react-redux';
const ListPayments = () => {
    const user = useSelector((state) => state.user.user);
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
    function getDate(UNIX_timestamp){
      var a = new Date(UNIX_timestamp);
      var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var hour = a.getHours();
      var min = a.getMinutes();
      var sec = a.getSeconds();
      var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
      return time;
    }
  return (
    <div>
     <div className="appointment-details" style={{marginTop: '40px'}}>
  <div className="details-top-section">
    <div className="cardiologist-details">
      <div className="payment-details-top">
        <strong>{user.name}</strong>
        <span>PATIENT ID: {user.patient_id}</span>
        <p>{formatPrice(user.balance)}</p>
      </div>
    </div>
    <div className="clinic-main">
        {user.payments.length > 0 &&
      <div className="charges-details">
        <h4 style={{color: 'white', textAlign: 'center', marginBottom: '20px'}}>PAYMENT HISTORY</h4>
        {user.payments.map((payment, index) => (
            <div key={index}  className="charges-text">

              {/* {payment.details !== null && JSON.parse(payment.details).map(detail => (
                <div style={{fontSize: '18px', color: 'white'}}>
                 <span >{detail.name ? detail.name: detail.title}</span> <br/>
                </div>
             ))} */}
             <span>{getDate(payment.created_at)}</span>
            <span>{formatPrice(payment.amount)}</span>
            <div className='' style={{display: 'flex', justifyContent: 'center',  alignItems: 'center', marginTop: '8px'}}>
                <div className='' style={{height: '8px', width: '8px', borderRadius: '4px', backgroundColor: `${payment.status === 'pending' ? 'red' : 'green'}`}}></div>
                
                <div className="" style={{marginLeft: '5px', fontSize: '14px', marginTop: '-5px', color: `${payment.status === 'pending' ? 'red' : 'green'}`}}>{payment.status}</div>
                </div>
          </div>
        ))}
      </div>
      }
    </div>
  </div>
</div>
</div>

  )
}

export default ListPayments
