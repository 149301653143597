import React, {useState, useEffect} from 'react'
import apiClient from '../../Request/http-common';
import { useMutation } from "react-query";
import { setAuthStatus, setToken } from './authSlice';
import { setUser } from './userSlice';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';

const Register = () => {

  const dispatch = useDispatch();
  const initialLogin = {
    name: '',
    email: '',
    password: '',
    date_of_birth: '',
    country: '',
    address: '',
    phone: '',
    password_confirmation: ''

}


const [countries, setCountries] = useState(null);

  const { isLoading: isLoadingcountries, refetch: getcountries } = useQuery(
      "view-countries",
      async () => {
        return await apiClient.get(`/api/countries`);
      },
      {
        enabled: false,
        onSuccess: (res) => {
          if (res.data.status === 'success') {
              setCountries(res.data.countries);
          }
        },
        onError: (err) => {
          // console.log(err.response?.data || err);
        },
      }
    );

  const [error, setErros] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [productForm, setproductForm] = useState(initialLogin);

  const onChange = (e) =>
    setproductForm({ ...productForm, [e.target.name]: e.target.value });

  const { isLoading: isSendingRequest, mutate: postProduct } = useMutation(
    async () => {

      return await apiClient.post(`/api/register`, {
        name: productForm.name,
        email: productForm.email,
        password: productForm.password,
        date_of_birth: productForm.date_of_birth,
        country: productForm.country,
        address: productForm.address,
        phone: productForm.phone,
        password_confirmation: productForm.password_confirmation,
        
      });
    },
    {
      onSuccess: (res) => {
            localStorage.setItem('auth-token', res.data.access_token);
            dispatch(setUser(res.data.user));
            dispatch(setAuthStatus('authenticated'));
            dispatch(setToken(res.data.access_token));
            setTimeout(() => {
              window.location.href  = '/';
            }, 1000);
      },
      onError: (err) => {  

        if (err.response?.data.hasOwnProperty('errors')) {
          let myerror = err.response?.data.errors || err;         
          setErros(myerror)   
                   
      }else{
          let errorMessage = err.response?.data.message || err ;
          setGeneralError(errorMessage);

          
      }      
             
      },
    }
  );


  const initialImage = {
    image: ''
}
const [productImage, setImage] = useState(initialImage);
const  onFileChange = (event) => { 
    setImage({...productImage, image: event.target.files[0]});
  };
  
  const onSubmit = async() =>{
    

    await apiClient.get("/sanctum/csrf-cookie").then(() => {
      postProduct();
        })
    
  }

  useEffect(() => {    
    getcountries();
  }, []);

  return (
    <div className="main-container" style={{backgroundColor: '#fff'}}>
  <div id="app">
    <div className="page">
      <div className="page-content">
        <div className="sign-in-page segments">
          {/* <div className="navbar sign-header">
            <div className="navbar-inner">
              <div className="back-link">
                <a className="link back" href="index.html">
                  <img className="back-icon" src="images/back-icon.svg" alt />
                </a>
              </div>
            </div>
          </div> */}
          <div className="sign-up-section">
            <div className="sign-up-top">
              <h2>Register</h2>
              <p>fill the form to create new account</p>
            </div>
            <div className="sign-up-form">
              <div className="sign-form-fild">
                <label>Name</label>
                <input onChange={onChange} name='name' type="text" placeholder="Lucy Martin" />   
                <div style={{color: 'red'}}>
                {(error !== '' && error.name)}
                </div>
              </div>
              <div className="sign-form-fild">
                <label>email</label>
                <input onChange={onChange} name='email' type="email" placeholder="me@example.com" /> 
                <div style={{color: 'red'}}>
                {(error !== '' && error.email)}
                </div>  
              </div>

              <div className="sign-form-fild">
                <label>password</label>
                <input onChange={onChange} name='password' type="password" placeholder='********' />  
                <div style={{color: 'red'}}>
                {(error !== '' && error.password)}
                </div>
              </div>

              <div className="sign-form-fild">
                <label>confirm password</label>
                <input onChange={onChange} name='password_confirmation' type="password" placeholder='********' />  
                <div style={{color: 'red'}}>
                {(error !== '' && error.password)}
                </div>
              </div>

              <div className="sign-form-fild">
                <label>D.O.B</label>
                <input onChange={onChange} name='date_of_birth' type="date" /> 
                <div style={{color: 'red'}}>
                {(error !== '' && error.date_of_birth)}
                </div> 
              </div>

              <div className="sign-form-fild">
               
                <label>country</label>
                <select onChange={onChange} name="country" id="country" style={{width: '150px'}}>
                  <option value="">Select Country</option>
                    {countries !== null && 

                    countries.map(country => (
                        <option key={country.id} value={country.name}>{country.name}</option>
                    ))
                    }
                
                </select>
                <div style={{color: 'red'}}>
                {(error !== '' && error.country)}
                </div>
              </div>

              <div className="sign-form-fild">
                <label>address</label>
                <input onChange={onChange} name='address' type="text" placeholder='2000' /> 
                <div style={{color: 'red'}}>
                {(error !== '' && error.address)}
                </div> 
              </div>

              <div className="sign-form-fild">
                <label>phone</label>
                <input onChange={onChange} name='phone' type="text" placeholder='+123456789' /> 
                <div style={{color: 'red'}}>
                {(error !== '' && error.phone)}
                </div> 
              </div>

            

            <div style={{color: 'red', marginTop: '20px', textAlign: 'center'}}>
                {(generalError !== '' && generalError.message || generalError) }
              </div>


              <div className="sign-botton">
                <a onClick={onSubmit} className="sign-up-btn" style={{cursor: 'pointer'}}>
                  
                  {isSendingRequest ? 'Sending...': 'Register'}
                  
                </a>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Register
