import React, {Fragment} from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';

const NavBar = () => {
    const sidebar = useSelector((state) => state.user.sidebar);
    const user = useSelector((state) => state.user.user);

  return (
    <div className="search-menu-bar" >
               
               <div className={` sidebar ${sidebar} `} style={{position: 'fixed', zIndex: 2000000 }}>
                 <ul className="nav-links nav-links-first">
                   <li>
                     <Link to="/profile">
                       <div className="link_name">
                         <div className="maclean-box">
                           <div className="menu-pic"><img className="back-icon" src={user.profile} alt /></div>
                           <div className="Maclean-text">{user.name}
                           <div style={{fontSize: '11px'}}>PATIENT ID: {user.patient_id}</div>
                           </div>
                         </div>
                       </div>
                     </Link>
                   </li>
                 </ul>

                 
                 <ul className="nav-links" style={{overflow: 'auto', height: '100%'}}>
                 <li><Link className="active" to="/"><span className="link_name">Home</span></Link></li>
                 <li><Link className="active" to="/profile"><span className="link_name">My Profile</span></Link></li>
                 <li><Link to="/doctors"><span className="link_name">My Appointments</span></Link></li>
                   <li><Link to="/doctors"><span className="link_name">My Doctors</span></Link></li>
                   <li><Link to="/pharmacy"><span className="link_name">My Medicines</span></Link></li>
                   <li><Link to="/list-test"><span className="link_name">My Diagnostics</span></Link></li>
                   <li><Link to="/pills-reminder"><span className="link_name">Pills Reminder</span></Link></li>
                   <li><Link to="/payment"><span className="link_name">Payment</span></Link></li>
                   <li><Link to="/list-payments"><span className="link_name">Payment History</span></Link></li>
                   {/* <li><Ltohref="/notifiication"><span className="link_name">Notification</spanLink</a></li> */}
                   {/* <li><Ltohref="/settings"><span className="link_name">Settings</span></a></Linki> */}
                   {user.is_admin === 'admin' &&
                   <Fragment>

                   <div className='' style={{textAlign: 'center', fontWeight: 'bolder',
                     borderTopStyle: 'double', color: 'white', marginBottom: '30px', paddingTop: '10px'}}>
                      ADMIN AREA
                    </div>
                    

                    <li><Link className="active" to="/upload-doctor"><span className="link_name">UPLOAD DOCTOR</span></Link></li>
                    <li><Link className="active" to="/upload-pharmacy"><span className="link_name">PHARMACY UPLOAD</span></Link></li>
                    <li><Link to="/upload-pill"><span className="link_name">UPLOAD PILL</span></Link></li>
                    <li><Link to="/upload-diagnostics"><span className="link_name">UPLOAD DIAGNOSTICS</span></Link></li>
                    <li><Link to="/upload-ambulance"><span className="link_name">UPLOAD AMBULANCE</span></Link></li>
                    <li><Link to="/upload-payment"><span className="link_name">ADD PAYMENT GATEWAY</span></Link></li>
                    
                    
                    <li><Link to="/list-admin-ambulance"><span className="link_name">LIST AMBULANCE</span></Link></li>
                    <li><Link to="/list-admin-dignostics"><span className="link_name">LIST DIAGNOSTICS</span></Link></li>
                    <li><Link to="/list-admin-doctors"><span className="link_name">LIST DOCTORS</span></Link></li>
                    <li><Link to="/list-admin-pharmacy"><span className="link_name">LIST PHARMACIES</span></Link></li>
                    <li><Link to="/list-admin-pills"><span className="link_name">LIST PILLS REMINDER</span></Link></li>
                    <li><Link to="/list-admin-payments"><span className="link_name">LIST  PAYMENTS</span></Link></li>
                    <li><Link to="/list-admin-payment-method"><span className="link_name">LIST PAYMENTS METHODS</span></Link></li>
                    <li><Link to="/list-admin-users"><span className="link_name">LIST USERS</span></Link></li>
                    </Fragment>
}
                    <div className='' style={{textAlign: 'center', height: '100px', fontWeight: 'bolder',
                     borderTopStyle: 'double', marginBottom: '30px', paddingTop: '10px'}}>
                    </div>

                    

                 </ul>
                 
               </div>
                                        
             </div>
  )
}

export default NavBar
