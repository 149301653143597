import React from 'react'
import { useSelector } from 'react-redux'
import PaymentButton from '../Payment/PaymentButton';
import { Link } from 'react-router-dom';
const ListDoctors = () => {
    const doctors = useSelector((state) => state.history.doctors);
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

  return (
    <div>
        {doctors.length > 0 &&
        <div className="services-section doctors-box">
        <div className="special-title">
          <h2>Doctors</h2>
          <Link to="/doctors" className="view-all-btn">View All</Link>
        </div>
        <div className="special-main">
            {doctors.map(doctor => (
                <div key={doctor.id} className="special-box">
                    <div className="special-text-box">
                    <div className="roboto-img" style={{height: '70px', width: '70px', borderRadius: '35px', backgroundColor: '#ebeff5'}}>
                        <img src={doctor.profile} alt={doctor.name} style={{height: '50px', width: '50px', borderRadius: '25px', marginTop: '10px'}} />
                        </div>
                    <div className="roboto-text">
                        <span><a href="#">{doctor.name}</a></span>
                        
                        <p >EXP: {doctor.experience}</p>
                        <p className='text-bold'><strong>{doctor.category}</strong></p>
                        <div style={{fontSize: '11px', marginTop: '8px', color: '#ba4371'}}>Service Cost: {formatPrice(doctor.amount)}</div>

                        <div className='' style={{display: 'flex', justifyContent: 'center',  alignItems: 'center', marginTop: '8px'}}>
                        <div className='' style={{height: '8px', width: '8px', borderRadius: '4px', backgroundColor: 'red'}}></div>
                        
                        <div className="" style={{marginLeft: '5px', fontSize: '14px', marginTop: '-5px', color: 'red'}}>{doctor.status}</div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>                        
                        
                        <a href={doctor.report} className="home-star" style={{backgroundColor: '#ba9906'}}>View Report</a>
                        <PaymentButton product={doctor} name="Pay" />
                        {/* <div className="home-star bg-red" style={{backgroundColor: 'green'}}>Pay</div> */}
                        </div>
                        

                        
                    </div>
                    </div>
                </div>
            ))}
        </div>
      </div>
        }
      
    </div>
  )
}

export default ListDoctors
