import React, {useState, useEffect} from 'react'
import apiClient from '../../Request/http-common';
import { useMutation } from "react-query";


import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const EditPaymentMethod = () => {

  const initialLogin = {
    name: '',
    details: '',

}

  const [error, setErros] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [productForm, setproductForm] = useState(initialLogin);
  const [successMessage, setSuccessMessage] = useState(null);


  const params = useParams();
  const [payment, setpayment] = useState(null);
  const [users, setUsers] = useState(null);
  const { isLoading: isLoadingpayments, refetch: getpayments } = useQuery(
      "edit-payment",
      async () => {
        return await apiClient.get(`/api/edit-payment-method/${params.id}`);
      },
      {
        enabled: false,
        onSuccess: (res) => {
          if (res.data.status === 'success') {
              setpayment(res.data.payment);
              setUsers(res.data.users);
              setproductForm({...productForm,

                    name: res.data.payment.name,
                    details: res.data.payment.details,
              });
          }
        },
        onError: (err) => {
          // console.log(err.response?.data || err);
        },
      }
    );


  const onChange = (e) =>
    setproductForm({ ...productForm, [e.target.name]: e.target.value });

  const { isLoading: isSendingRequest, mutate: postProduct } = useMutation(
    async () => {
        const formData = new FormData();
        formData.append('payment_id', params.id);
        formData.append('name', productForm.name);
        formData.append('details', productForm.details);
        

      return await apiClient.post(`/api/update-payment-method`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      onSuccess: (res) => {
        if (res.data.status === 'success') {
          setSuccessMessage(res.data.message);
        }
      },
      onError: (err) => {   
        if (err.response?.data.hasOwnProperty('errors')) {
          let myerror = err.response?.data.errors || err;         
          setErros(myerror)   
                   
      }else{
          let errorMessage = err.response?.data.message || err ;
          setGeneralError(errorMessage);          
      }       
             
      },
    }
  );


  const initialImage = {
    image: ''
}
const [productImage, setImage] = useState(initialImage);
const  onFileChange = (event) => { 
    setImage({...productImage, image: event.target.files[0]});
  }; 

  useEffect(() => {
    getpayments();
  }, []);

  return (
    <div className="main-container" style={{backgroundColor: '#fff'}}>
  <div id="app">
    {payment !== null && 
    <div className="page">
      <div className="page-content">
        <div className="sign-in-page segments">
       
          <div className="sign-up-section">
            <div className="sign-up-top">
              <h2>Update payment method</h2>
              <p>fill the form to edit payment method</p>
            </div>
            <div className="sign-up-form">
              

              <div className="sign-form-fild">
                <label>name</label>
                <input defaultValue={payment.name} onChange={onChange} name='name' type="text" placeholder='2000' /> 
                <div style={{color: 'red'}}>
                {(error !== '' && error.name)}
                </div> 
              </div>


              <div className="sign-form-fild">
                <label>details</label>
                <textarea defaultValue={payment.details} style={{width: '100%', height: '120px'}} onChange={onChange} name='details' type="text" placeholder='write...' >
                </textarea>
                <div style={{color: 'red'}}>
                {(error !== '' && error.details)}
                </div> 
              </div>
            

            <div style={{color: 'red', marginTop: '20px', textAlign: 'center'}}>
                {(generalError !== '' && generalError.message || generalError) }
              </div>
                    
                  {successMessage !== null &&
                    <div style={{color: 'green', marginTop: '20px', textAlign: 'center'}}>
                    {successMessage}
                  </div>
                  }
              


              <div className="sign-botton">
                <a onClick={postProduct} className="sign-up-btn" style={{cursor: 'pointer', color: 'white'}}>
                  
                  {isSendingRequest ? 'Uploading...': 'Upload payment'}
                  
                </a>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
}
  </div>
</div>

  )
}

export default EditPaymentMethod
